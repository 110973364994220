<template>
	<div class="max-w-7xl mx-auto px-6">
		<div class="mt-6">
			<h1 class="text-center md:text-left text-4xl font-bold">Live Drops</h1>

			<index-carousel :slides="carouselSlides" :interval="10000" />
		</div>

		<div class="mt-16">
			<h1 class="text-center md:text-left text-4xl font-bold">Trending Collections</h1>
			<div
				class="mt-2 space-y-4 grid grid-cols-1 gap-6 items-stretch sm:grid-cols-2 lg:grid-cols-4 md:space-y-0 md:mx-0 md:mt-4"
			>
				<router-link
					custom
					v-slot="{ href }"
					v-for="storefront in featuredStorefronts"
					:key="`storefront-${storefront.id}`"
					:to="{
						name: 'storefront',
						params: { slug: storefront.slug },
					}"
				>
					<a
						:href="href"
						class="cursor-pointer flex flex-col rounded-xl overflow-hidden shadow-lg shadow-gray-600/50 group hover:scale-105 transition-transform"
					>
						<div class="flex-none z-10">
							<img
								:src="storefront.featured_image"
								:alt="`${storefront.name}: ${storefront.group_name}`"
							/>
						</div>
						<div class="flex flex-grow p-4 flex-col bg-white">
							<div class="font-normal text-gray-400 text-sm">
								{{ storefront.group_name }}
							</div>
							<div class="font-poppins font-semibold text-lg">{{ storefront.name }}</div>
						</div>
					</a>
				</router-link>
			</div>
		</div>

		<div class="mt-16" v-if="state.rewardAssets.length > 0">
			<h1 class="text-center md:text-left text-4xl/10 font-bold mb-4">
				Reward Store

				<router-link
					:to="{
						name: 'storefront',
						params: { slug: 'reward-room' },
					}"
					class="hidden md:inline-block font-light text-gray-800 text-2xl/10 align-middle ml-4 hover:text-sky-600"
				>
					All Rewards
					<span class="text-lg/10"><i class="far fa-chevron-right"></i></span>
				</router-link>
			</h1>
			<div class="mt-2 grid grid-cols-2 gap-6 place-items-start md:grid-cols-6 md:mx-0 md:mt-4 items-">
				<home-rewards-store-listing
					v-for="asset in state.rewardAssets"
					:asset="asset.name"
					collection="Blokpax Reward Room"
					:img="asset.image"
					:bpx_price="`${asset.floor_listing?.price ?? 0}`"
					:url="'/store/reward-room/' + asset.id"
				/>
			</div>
			<div class="mt-8 md:hidden">
				<router-link
					custom
					v-slot="{ navigate }"
					:to="{
						name: 'storefront',
						params: { slug: 'reward-room' },
					}"
				>
					<button
						class="border border-sky-700 hover:border-sky-600 py-2 px-4 text-xl font-poppins rounded-lg"
						@click="navigate"
					>
						All Rewards
						<span class="text-lg/10"><i class="far fa-chevron-right"></i></span>
					</button>
				</router-link>
			</div>
		</div>

		<div class="mt-16 md:grid grid-cols-2 gap-16 space-y-16 md:space-y-0">
			<div>
				<h1 class="text-center md:text-left text-4xl font-bold">Recent Notable Sales</h1>
				<div class="grid grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16 mt-8">
					<home-notable-sale
						v-for="sale in recentNotableSales"
						:asset="sale.asset"
						:collection="sale.collection"
						:img="sale.image"
						:bpx_price="sale.bpx_unit_price * 1e9"
						:url="sale.url"
						:usd_override="sale?.usd_unit_price"
					/>
				</div>
			</div>
			<div>
				<h1 class="text-center md:text-left text-4xl font-bold">Resources</h1>
				<div class="mt-6 grid grid-cols-[1fr,4fr] gap-x-4 gap-y-12 items-center font-bold text-lg">
					<div>
						<a
							href="https://youtu.be/HYCFOyGlW4o?si=33LlaL1nKLor8Mhd"
							rel="nofollow noreferrer"
							target="_blank"
							class="cursor-pointer"
						>
							<img
								src="/image/getting-started-video-300x.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a
							href="https://youtu.be/HYCFOyGlW4o?si=33LlaL1nKLor8Mhd"
							rel="nofollow noreferrer"
							target="_blank"
							class="cursor-pointer"
						>
							<p class="text-gray-400 text-sm font-normal">Getting Started?</p>
							<p>Watch This First!</p>
						</a>
					</div>

					<div>
						<a
							href="https://help.blokpax.com/experiences/hth/overview"
							target="_blank"
							class="cursor-pointer"
						>
							<img
								src="/image/collectors_guide-300x.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a
							href="https://help.blokpax.com/experiences/hth/overview"
							target="_blank"
							class="cursor-pointer"
						>
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>Collector's Guide</p>
						</a>
					</div>

					<div>
						<a href="https://help.blokpax.com/ecosystem/points/hth" target="_blank" class="cursor-pointer">
							<img
								src="/image/scoring_explained-300x.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a href="https://help.blokpax.com/ecosystem/points/hth" target="_blank" class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>Scoring Explained</p>
						</a>
					</div>

					<div>
						<a href="https://set-builder.collecthth.com/" target="_blank" class="cursor-pointer">
							<img
								src="/image/collector_leaderboard-300x.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a href="https://set-builder.collecthth.com/" target="_blank" class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>Set Builder</p>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useMarketStore } from '@/stores/MarketStore'
import HomeNotableSale from '@/components/HomeNotableSale.vue'
import HomeRewardsStoreListing from '@/components/HomeRewardsStoreListing.vue'
import IndexCarousel, { type Slide } from '@/components/IndexCarousel.vue'

import recentNotableSales from '@/static/recent-notable-sales.json'

const marketStore = useMarketStore()

const carouselSlides: Array<Slide> = [
	{
		title: 'Drop 2: Football 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/football-24-drop-2-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: 'https://collecthth.com/',
	},
	{
		title: 'Drop 1: Football 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/football-24-drop-1-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: 'https://collecthth.com/football-24-1',
	},
	{
		title: 'Drop 3: Baseball 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/baseball-24-drop-3-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: 'https://collecthth.com/baseball-24-3',
	},
	{
		title: 'Drop 2: Baseball 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/baseball-24-drop-2-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: 'https://collecthth.com/baseball-24-2',
	},
	{
		title: 'Drop 1: Baseball 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/baseball-24-drop-1-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: 'https://collecthth.com/baseball-24-1',
	},
	{
		title: 'Football 2023',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/football-23.gif',
		cta: 'Buy and Rip Instantly!',
		link: 'https://collecthth.com/football-23',
	},
	{
		title: 'Baseball 2023',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/baseball-23.gif',
		cta: 'Buy and Rip Instantly!',
		link: 'https://collecthth.com/baseball-23',
	},
].map((v, i) => {
	const r = { key: i, ...v }
	return r
})

const state = reactive({
	rewardAssets: [],
})

const featuredStorefronts = computed(() =>
	marketStore.storeFronts
		.filter((storefront) => storefront.featured_storefront_sort_order !== null)
		.sort((a, b) => a.featured_storefront_sort_order - b.featured_storefront_sort_order)
)

onMounted(async () => {
	state.rewardAssets = await marketStore.loadFeaturedListings()
})
</script>
